import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 610.000000 407.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,407.000000) scale(0.100000,-0.100000)">



<path d="M1815 2522 c-89 -30 -159 -74 -221 -137 -73 -74 -118 -159 -140 -265
-53 -253 113 -521 370 -595 81 -24 239 -17 317 14 244 95 387 362 324 605 -44
167 -166 306 -323 367 -77 30 -253 36 -327 11z m235 -62 c115 -22 249 -120
300 -220 50 -99 66 -232 40 -331 -31 -115 -127 -232 -234 -284 -83 -40 -203
-53 -291 -31 -92 23 -155 57 -212 114 -197 197 -170 522 56 680 95 67 224 94
341 72z"/>
<path d="M2051 2120 c-62 -37 -117 -74 -122 -81 -10 -17 7 -49 28 -49 9 0 51
20 94 45 43 25 80 45 83 45 12 0 16 -62 6 -102 -13 -49 -53 -100 -73 -92 -15
5 -61 -52 -48 -60 17 -11 71 6 71 21 0 9 13 31 30 49 35 41 54 104 46 160 -5
37 -2 43 24 63 17 12 30 26 30 31 0 13 -30 40 -43 39 -7 0 -63 -31 -126 -69z"/>
<path d="M2607 2493 c-9 -8 -9 -928 0 -936 3 -4 12 -2 20 4 10 9 13 97 13 459
0 247 -3 455 -6 464 -7 17 -16 20 -27 9z"/>
<path d="M4364 2409 c-72 -17 -134 -97 -150 -195 -9 -61 20 -162 58 -201 45
-46 87 -63 157 -63 135 0 213 84 215 230 1 66 -3 81 -30 130 -25 43 -42 61
-84 83 -55 28 -93 32 -166 16z m132 -58 c100 -46 117 -246 26 -321 -31 -26
-45 -30 -94 -30 -99 0 -148 62 -148 187 0 52 5 76 21 103 46 75 115 97 195 61z"/>
<path d="M2768 2404 c-5 -4 -8 -105 -8 -225 l0 -218 46 -7 c160 -24 287 44
314 169 24 107 -11 200 -94 252 -45 27 -58 30 -150 33 -56 2 -104 0 -108 -4z
m212 -64 c54 -27 80 -80 80 -160 0 -66 -9 -90 -48 -132 -28 -30 -104 -52 -162
-46 l-34 3 1 175 c1 96 2 176 3 178 0 1 27 2 60 2 41 0 74 -7 100 -20z"/>
<path d="M3230 2180 l0 -230 145 0 145 0 0 31 0 30 -97 -1 c-54 -1 -106 -1
-115 -1 -16 1 -18 12 -18 81 l0 80 95 0 c95 0 95 0 95 25 0 25 0 25 -95 25
l-95 0 0 70 0 70 105 0 105 0 0 25 0 25 -135 0 -135 0 0 -230z"/>
<path d="M3610 2180 l0 -230 136 0 135 0 -3 28 -3 27 -102 0 -103 0 0 187 c0
102 -3 193 -6 202 -3 9 -17 16 -30 16 l-24 0 0 -230z"/>
<path d="M3830 2385 c0 -24 2 -25 70 -25 l70 0 0 -205 0 -205 30 0 30 0 2 203
3 202 73 3 c69 3 72 4 72 27 l0 25 -175 0 -175 0 0 -25z"/>
<path d="M4758 2404 c-5 -4 -8 -108 -8 -231 l0 -223 30 0 30 0 0 88 0 87 68 2
c122 3 182 47 182 135 0 106 -45 140 -195 146 -55 2 -103 1 -107 -4z m188 -54
c36 -14 53 -38 54 -77 0 -66 -27 -87 -127 -97 l-63 -7 0 96 0 95 54 0 c30 0
67 -5 82 -10z"/>
<path d="M1114 2245 c-12 -31 7 -35 156 -35 116 0 149 3 153 14 12 30 -16 36
-160 36 -116 0 -145 -3 -149 -15z"/>
<path d="M1067 2114 c-4 -4 -7 -16 -7 -26 0 -17 10 -18 153 -16 144 3 152 4
152 23 0 19 -8 20 -146 23 -80 1 -148 -1 -152 -4z"/>
<path d="M1063 1965 c-11 -31 13 -36 158 -33 136 3 144 4 144 23 0 19 -8 20
-148 23 -127 2 -148 0 -154 -13z"/>
<path d="M1114 1825 c-12 -30 7 -35 150 -35 114 0 146 3 156 15 24 29 0 35
-151 35 -122 0 -151 -3 -155 -15z"/>
<path d="M4090 1790 c0 -24 -3 -28 -17 -23 -17 5 -17 5 -1 -8 9 -8 19 -30 21
-49 2 -19 5 -2 5 38 1 39 0 72 -3 72 -3 0 -5 -13 -5 -30z"/>
<path d="M2764 1786 c1 -11 14 -27 31 -36 16 -9 32 -27 36 -41 7 -23 7 -23 8
4 1 23 -5 31 -33 42 -19 8 -36 23 -39 32 -3 15 -4 15 -3 -1z"/>
<path d="M3850 1786 c0 -21 5 -26 23 -25 12 0 16 3 10 6 -7 2 -13 11 -13 18 0
8 -4 17 -10 20 -6 4 -10 -5 -10 -19z"/>
<path d="M2879 1793 c-7 -54 -7 -103 -1 -107 5 -3 10 22 11 54 1 33 -1 60 -4
60 -3 0 -5 -3 -6 -7z"/>
<path d="M2987 1729 c3 -24 7 -46 9 -48 9 -10 3 65 -5 78 -6 10 -8 -1 -4 -30z"/>
<path d="M3035 1733 c20 -48 31 -59 39 -38 3 9 2 14 -3 10 -5 -3 -15 11 -21
30 -6 19 -16 35 -21 35 -5 0 -2 -17 6 -37z"/>
<path d="M3360 1747 c7 -19 7 -35 -1 -50 -10 -21 -10 -21 5 -3 20 25 20 37 1
62 -14 18 -14 17 -5 -9z"/>
<path d="M3411 1723 c1 -53 6 -49 11 10 2 20 0 37 -4 37 -4 0 -8 -21 -7 -47z"/>
<path d="M3531 1723 c1 -53 6 -49 11 10 2 20 0 37 -4 37 -4 0 -8 -21 -7 -47z"/>
<path d="M3597 1729 c3 -24 7 -46 9 -48 9 -10 3 65 -5 78 -6 10 -8 -1 -4 -30z"/>
<path d="M3480 1717 c4 -41 5 -41 8 -8 2 18 0 37 -5 42 -5 5 -6 -11 -3 -34z"/>
<path d="M3647 1753 c-10 -9 -9 -51 1 -57 5 -3 8 11 8 29 -1 19 -1 35 -2 35 0
0 -4 -3 -7 -7z"/>
<path d="M3757 1753 c-13 -12 -7 -51 10 -64 15 -13 15 -12 4 10 -7 12 -11 32
-8 42 5 20 4 23 -6 12z"/>
<path d="M3984 1739 c0 -10 4 -19 8 -19 4 0 8 6 8 14 0 8 -4 17 -8 20 -4 2 -8
-4 -8 -15z"/>
<path d="M3191 1729 c0 -15 7 -33 17 -40 15 -12 16 -12 5 1 -6 8 -13 26 -16
40 l-4 25 -2 -26z"/>
<path d="M3287 1743 c-10 -9 -9 -41 2 -47 4 -3 8 8 7 24 -1 17 -1 30 -2 30 0
0 -4 -3 -7 -7z"/>
<path d="M3850 1728 c0 -13 5 -30 10 -38 7 -11 10 -5 10 23 0 20 -4 37 -10 37
-5 0 -10 -10 -10 -22z"/>
<path d="M3910 1724 c0 -22 12 -44 23 -44 7 0 7 4 -1 12 -19 19 -14 26 16 30
26 3 26 4 -5 9 -23 4 -33 2 -33 -7z"/>
<path d="M4021 1724 c-1 -12 6 -28 16 -35 15 -13 15 -12 6 6 -6 11 -13 27 -16
35 -4 10 -6 8 -6 -6z"/>
<path d="M2930 1695 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
